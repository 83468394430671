import { ref } from 'vue'
import { useObservable } from '@golden/shared-vue'
import { Subject, finalize, map, switchMap, tap } from 'rxjs'
import { GDKError } from '@golden/gdk-agent-next'
import gdk from '@/gdk'
import { useToast } from 'primevue/usetoast'
import { urlHelper } from '@/utils/urlHelper'

export function useBannerNotification () {
  const loading = ref(true)
  const toast = useToast()

  const flow$ = new Subject<void>()
  const bannerNotifications = useObservable(flow$.pipe(
    tap(() => { loading.value = true }),
    switchMap(() => gdk.notification.getBannerNotification()),
    map((res) => res.data),
    finalize(() => { loading.value = false })
  ), null)

  const gotoBannerLink = (id: number) => {
    gdk.notification.getBannerNotificationLink(id).subscribe({
      next: (res) => {
        const href = res.url.startsWith('https://') ? res.url : `${urlHelper.get('main')}${res.url}`
        window.open(href)
      },
      error: (err: GDKError) => {
        void flow$.next()
        void toast.add({ severity: 'error', detail: (err as Error).message, life: 1000 })
      }
    })
  }

  const reload = () => {
    flow$.next()
  }

  reload()

  return {
    loading,
    bannerNotifications,
    gotoBannerLink,
    reload
  }
}
