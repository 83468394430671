<template>
  <div
    :class="{
      'item': true,
      'h-50px': (type === 'desktop'),
      'h-72px': (type === 'mobile'),
      'px-14px': true,
      'rd-6px': true,
      'banner-notification-shadow': (type === 'mobile')
    }"
    @click="() => { if (type === 'desktop') click() }"
  >
    <img
      class="icon"
      src="@/assets/images/banner-notification-icon.png"
    >
    <div class="text pl-7px">
      <div class="title">
        {{ item.title }}
      </div>
      <div
        v-if="type==='mobile'"
        class="sub-title color-zinc-400"
      >
        {{ item.sub_title }}
      </div>
    </div>
    <div
      v-if="type === 'mobile'"
      class="link-button ml-auto h-48px pl-14px"
    >
      <button
        class="bg-[var(--primary-color)] w-72px h-26px border-none rd-50px p-4px"
        @click="() => { click() }"
      >
        <span class="color-white text-size-12px">{{ t('common.checkNow') }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BannerNotification } from '@golden/gdk-agent-next'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'BannerNotification',
  props: {
    type: {
      type: String as PropType<'desktop' | 'mobile'>,
      default: 'mobile'
    },
    item: {
      type: Object as PropType<BannerNotification>,
      required: true
    }
  },
  emits: ['click'],
  setup (_props, context) {
    const { t } = useI18n()

    const click = () => {
      context.emit('click')
    }
    return {
      t,
      click
    }
  }
})

</script>

<style lang="scss" scoped>
.item {
  border: 1px solid #fff;
  background: linear-gradient(-90deg, rgba(250, 245, 214, 1) 0%, rgba(255, 255, 255, 1) 100%);
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  & + .item {
    margin-top: 10px;
  }

  &.banner-notification-shadow {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  }
  .icon {
    width: 48px;
    height: 48px;
    @media screen and (min-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    width: 175px;
    @media screen and (min-width: 768px) {
      width: 110px;
    }
    .title {
      color: var(--text-color);
      font-size: 1.4em;
      line-height: 1.4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .sub-title {
      font-size: 1.2em;
      line-height: 1.2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .link-button {
    display: flex;
    align-items: center;
  }
}
</style>
